import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EmailField from '@/shared/fields/email-field';

const label = (name) => i18n.t(`USER.FIELDS.${name.toUpperCase()}`);

const fields = {
  email: new EmailField('email', label('email'), {
    required: true,
  }),
  password: new StringField('password', label('password'), {
    required: true,
  }),
  password_confirm: new StringField('password_confirm', label('password_confirm'), {
    required: true,
  }),
  full_name: new StringField('full_name', label('full_name'), {
    required: true,
  }),
  email_mentor: new EmailField('email_mentor', label('email'), {
    required: true,
  }),
  password_mentor: new StringField('password_mentor', label('password'), {
    required: true,
  }),
  full_name_mentor: new StringField('full_name_mentor', label('full_name'), {
    required: true,
  }),
};

export default class UserModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
