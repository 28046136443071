import * as yup from 'yup';
import StringField from '@/shared/fields/string-field';

export default class EmailField extends StringField {
  forFormRules = () => {
    let yupValidator = yup
      .array()
      .label(this.label)
      .of(
        yup
          .string()
          .email('Email is invalid')
          .label('Email')
          .max(255)
          .required(),
      );

    if (this.required) {
      yupValidator = yupValidator.required();
    }

    const validator = (rule, value, callback) => {
      try {
        yupValidator.validateSync(value);
        callback();
      } catch (error) {
        callback(error);
      }
    };

    return [{ validator }];
  }
}
